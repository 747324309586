<template>
  <div class="trueBody" :style="trueBodyStyleObj">
    <div class="colorPickerDiv">
      <color-picker ref="colorPicker" @change="changeBackgroundColor"></color-picker>
    </div>

    <div class="showArea">
      <span class="wordSpell" v-show="spellSpan">{{ this.wordJson["word"] }}</span>
      <span class="InputAreaSpan" v-show="inputAreaSpan"><input class="InputArea" type="text" v-model="examInputText"
                                                            placeholder="Please type"></span>
      <br/>
      <span class="wordAccent">{{ this.wordJson["accent"] }}</span>
      <br/>
      <span v-show=cnMeanStatus class="cnMean">{{ this.wordJson["mean_cn"] }}</span>
      <span v-show=cnMeanPlaceHolder class="cnMean">&nbsp;</span>
    </div>
    <div class="allButtonsDiv">
      <button v-show=welcomeButtons v-on:click="startRevise">继续学习</button>
      <button v-show=welcomeButtons v-on:click="startExam">开始考试</button>

      <button v-show=reviseButtons v-on:click="wordKill">标记熟练</button>
      <button v-show=reviseButtons v-on:click="wordHint">中文解释{{ cnMeanStatusContent }}</button>
      <button v-show=reviseButtons v-on:click="wordPass">下一个→</button>

      <button v-show=examButtons v-on:click="startRevise">返回</button>
      <button v-show=examButtons v-on:click="wordHint">中文解释{{ cnMeanStatusContent }}</button>
      <button v-show=examButtons v-on:click="wordGetScore">提交</button>
      <div v-show="killRuleNoteDiv" class="killRuleNoteDiv">拼写正确提交，则可标记为熟练</div>

    </div>
  </div>
</template>

<script>
import ColorPicker from "./ColorPicker";

export default {
  name: "WordBrowse",
  components: {ColorPicker},
  data: () => ({
    API_HOST:process.env.VUE_APP_URL,
    wordRevise_API_URL: `/api/word-revise`,
    examKill_API_URL: `/api/exam-kill`,
    wordJson: {
      "word": "hello", "accent": "/hə'ləu/", "mean_cn": "n. 表示问候， 惊奇或唤起注意时的用语", "word_length": 5,
      "topic_id": 0
    },
    cnMeanStatusContent: '[开启]',
    cnMeanStatus: false,
    cnMeanPlaceHolder: true,
    welcomeButtons: true,
    reviseButtons: false,
    examButtons: false,
    spellSpan: true,
    killRuleNoteDiv: false,
    inputAreaSpan: false,
    examInputText: "",
    trueBodyStyleObj: {
      backgroundColor: "white"
    }

  }),
  props: {},
  methods: {
    changeBackgroundColor() {
      this.trueBodyStyleObj.backgroundColor = this.$refs.colorPicker.getPickedColor()
    },
    startRevise() {
      this.welcomeButtons = false
      this.reviseButtons = true
      this.examButtons = false
      this.killRuleNoteDiv = false
      this.inputAreaSpan = false
      this.spellSpan = true
      if (this.wordJson.word === "hello") {
        this.fetchNextTopic()
      }
    },
    startExam() {
      if (this.wordJson.word === "hello") {
        this.fetchNextTopic().catch(
            this.fetchNextTopic().catch(
                this.cnMeanStatus = true
            ))
      }
      this.clearInput()
      this.welcomeButtons = false
      this.spellSpan = false
      this.inputAreaSpan = true
      this.reviseButtons = false
      this.examButtons = true
      this.killRuleNoteDiv = true
    },
    wordPass() {
      //insert into wordPass
      this.fetchNextTopic()
    },
    wordKill() {
      //insert into wordLearn
      this.spellSpan = false
      this.inputAreaSpan = true
      this.reviseButtons = false
      this.examButtons = true
      this.killRuleNoteDiv = true
    },
    wordHint() {
      if (this.cnMeanStatus === false) {
        this.cnMeanStatusContent = '[关闭]'
        this.cnMeanStatus = true
        this.cnMeanPlaceHolder = false
      } else if (this.cnMeanStatus === true) {
        this.cnMeanStatusContent = '[开启]'
        this.cnMeanStatus = false
        this.cnMeanPlaceHolder = true
      }

    },
    clearInput() {
      this.examInputText = ""
    },
    wordGetScore() {
      if (this.examInputText === this.wordJson["word"]) {
        this.examKillTopic()
      } else {
        alert("Wrong!")
        this.examButtons = false;
        this.reviseButtons = true;
        this.inputAreaSpan = false;
        this.spellSpan = true;
        this.killRuleNoteDiv = false;
      }
    },
    async fetchNextTopic() {
      const url = `${this.wordRevise_API_URL}`
      console.log(url)
      this.wordJson = await (await fetch(url)).json().catch(
          this.wordJson = {
            "word": "Connection Error", "accent": "", "mean_cn": "后台连接错误", "word_length": 5,
            "topic_id": 0
          }
      )
    },
    async examKillTopic() {
      const url = `${this.examKill_API_URL}`
      const result = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({"topic_id": this.wordJson.topic_id})// body data type must match "Content-Type" header
      });
      result.text().then(function (text) {
            if (text === "true") {
              alert("Good job")
              this.startRevise()
            } else {
              alert("Good job!\nbut system failed to mark this word\nplease try again later")
            }
          }
      )
      ;

    }


  },

}

</script>

<style scoped>
.trueBody {
  position: absolute;
  height: 95%;
  width: 98%;
  text-align: center;
  border: 2px solid #2c3e50;
}

.killRuleNoteDiv {
  font-size: large;
  margin-top: 5%;
}

.colorPickerDiv {
  width: 10%;
  height: 20%;
}

.InputArea {
  font-size: 40px;
  height: 2cm;
  width: 30%;
}

.allButtonsDiv {
  margin-right: auto;
}

.wordSpell {
  font-size: xxx-large;
}

.wordAccent {
  font-size: large;
}

.cnMean {
  font-size: medium;
}
</style>