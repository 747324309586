<template>
  <button @click="gotoWordRevise">背单词</button>
  <button @click="gotoResumeGolang">Resume golang</button>
  <button @click="gotoResumeSelect">Resume Select</button>
  <button @click="test">test</button>
</template>

<script>
export default {
  name: "RoutesTable",
  methods: {
    gotoWordRevise() {
      this.$router.push('/word-revise')
    },
    gotoResumeGolang() {
      this.$router.push('/resume-golang')
    },
    gotoResumeSelect() {
      this.$router.push('/resume-select')
    },
    test() {
      this.$router.push('/test')
    }
  }
}
</script>

<style scoped>

</style>