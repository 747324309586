<template>
  <div>
    Welcome to room 404
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
  data: () => ({
    API_HOST:process.env.VUE_APP_URL,
    detectInvaitions_API_URL: `/detectInvaitions`,
    user_ip:'12'
  }),
  methods: {
    // async detectInvaitions() {
    //   const url = `$${this.detectInvaitions_API_URL}`
    //   const result = await fetch(url, {
    //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'same-origin', // include, *same-origin, omit
    //     headers: {
    //       'Content-Type': 'application/json'
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     redirect: 'follow', // manual, *follow, error
    //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //     body: JSON.stringify({"a": this.ip})// body data type must match "Content-Type" header
    //   });
    //   result.text().then(function (text) {
    //         if (text === "true") {
    //
    //         } else {
    //
    //         }
    //       }
    //   )
    //   ;
    //
    // }
  }
}


</script>

<style scoped>
div {
  position: relative;
  margin: -8px;
  width: 100vw;
  height: 100vh;
  font-size: xxx-large;
  font-weight: bolder;
  text-shadow:-1px 0 black,0 1px black,1px 0 black,0 -1px black;
  color: #DCD2BB;
  background: #DDDFD0;
}
</style>