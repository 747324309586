<template>
  <div id="top-div">
    <div id="left-half">
      <div id="left-top">
        <img src="../../assets/me.png" alt="Hello World">
      </div>
      <div id="left-middle">
        <h3 class="itemtitle">&nbsp;求职意向</h3>
        <p>Golang后端开发</p>
        <h3 class="itemtitle">个人优势</h3>
        <p>热爱技术, 热爱生活</p>
        <p>擅长自驱动, 目标管理, 快速扩展技术栈</p>
        <p>熟悉常用的数据结构与算法, 熟悉应用设计模式</p>
        <p>了解分布式架构</p>
        <p>了解项目管理</p>
        <h3 class="itemtitle">&nbsp;证书</h3>
        <p>RHCE红帽认证</p>
        <p>CCNA思科认证</p>
        <p>中级网络工程师</p>
        <p>CET4/CET6</p>

      </div>
      <div id="left-bottom">
        <h3 class="itemtitle">&nbsp;教育经历</h3>
        <p>2013.09-2017.07</p>
        <p>沈阳工程学院</p>
        <p>（统招本科）</p>
        <p>物联网工程专业 </p>
        <p>2014辽宁省大学生计算机设计竞赛三等奖</p>
      </div>

    </div>
    <div id="right-half">
      <div id="right-top">
        <h3 class="itemtitle">&nbsp;个人简介</h3>
        <p id="name">姓名：孙思原</p>
        <p id="nickname">昵称：抖来眯</p>
        <p>Email: siysun@outlook.com</p>
        <p>Tel/Wechat: 15042495073</p>
        <p>个人主页：<a href="https://urousi.com/resume-golang">urousi.com</a></p>


        <h3 class="itemtitle">专业技能</h3>
        <p>1.Golang相关： go gc原理, go 并发, gpm模型, grpc, Beego</p>
        <p>2.中间件、缓存：Rabbitmq, Redis, Etcd</p>
        <p>3.数据库：Mysql/Mariadb, Mongodb, Elasticsearch, PostgreSQL, Sqlite</p>
        <p>4.运维：Linux(RHCE), shell, git/gitlab ee CI/CD, Ansible, Docker, K8S等 </p>
        <p>5.网络：了解主流网络协议, 网络运维基础(CCNA)</p>

      </div>

      <div id="right-middle">
        <h3 class="itemtitle">&nbsp;工作经历</h3>
        <p class="emphasize">2021/01-至今&nbsp;&nbsp;自助设计实现个人网站APP </p>
        <p class="emphasize">主要经历：</p>
        <p>1.重新制定职业发展规划，主要应用技术从python开发运维方向转为golang后端开发方向</p>
        <p>2.建立阿里云个人网站 </p>
        <p>3.设计实现背单词app，前端vue，后端beego</p>
        <p class="emphasize">2017/08-2020/11&nbsp;&nbsp;Cisco Systems Dalian 后端研发工程师（全职） </p>
        <p class="emphasize">主要职责：</p>
        <p>1.参与“Enterprise网络分析系统”多个子项目的开发</p>
        <p>2.主要负责开发项目的监控运维需求相关api, 协助管理项目的所需运维功能, 提供系统旧版本开发支持 </p>
        <p>3.协助团队进行功能优化设计、架构优化设计、流程简化和优化实时效率</p>
        <p>4.关注用户反馈, 应对反馈中提出的临时紧急需求, 将直接成果对接交付给网络工程师以及开发工程师</p>
        <hr/>
        <p class="emphasize">2016/10-2017/07&nbsp;&nbsp;Cisco Systems Dalian 后端研发工程师（实习）</p>
        <p class="emphasize">主要职责：</p>
        <p>1.依据敏捷开发的开发要求, 快速实现多个相关项目的功能原型, 逐步更新优化并实现新的架构设计</p>
        <p>2.对接网络工程师, 解构网络工程师需求, 设计实现并交付</p>
      </div>
      <div id="right-bottom">
        <h3 class="itemtitle">&nbsp;项目经历</h3>
        <p>概述：本人参与项目都与企业级网络分析相关, 总称“Enterprise网络分析系统”,简称EE, 分为8个子项目：</p>
        <p>&emsp;&emsp;&emsp;1.EE-AE&emsp;2.EE-CH&emsp;3.EE-CT&emsp;4.EE-V1&emsp;5.EE-V2&emsp;
          6.EE-M&emsp;7.EE-NA&emsp;8.EE-SE</p>
        <p>&emsp;&emsp;&emsp;参与开发过其中6个子项目，对应上述的1-6, 其中参与的项目1-4主要使用python, 项目5、6中开始使用golang</p>
        <p class="emphasize">&emsp;&emsp;&emsp;由于各个项目互相具有一定的关联性, 为了方便了解, 下面按照业务数据流的顺序介绍</p>
        <p class="emphasize">一、网络配置储存微服务（EE-CH）</p>
        <p><i class="emphasize">项目职务：</i>后端开发</p>
        <p><i class="emphasize">项目简介：</i>EE的数据储存预处理微服务</p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>1.对不同网络设备, 网络系统产生的数据开发对应的数据清洗功能</p>
        <p>2.通过数据分析, 挖掘出有价值的数据并进行统计, 将结果反馈给团队成员</p>
        <p><i class="emphasize">应用技术：</i>Python, mongodb, elasticsearch</p>
        <p><i class="emphasize">项目难点：</i></p>

        <p><i class="emphasize">项目产出：</i>分析统计出的有价值信息被设计为展示信息纳入系统, 为团队提供了有效的功能验证方法, 从而提升了开发效率</p>
        <hr/>
        <p class="emphasize">二、网络配置分析微服务（EE-AE）</p>
        <p><i class="emphasize">项目职务：</i>后端开发</p>
        <p><i class="emphasize">项目简介：</i>将EE-CT作为数据源, 解析网络设备配置, 获得网络分析结果数据</p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>1.参与网络设备配置数据解析微服务开发</p>
        <p>提供可视化数据产出数据基础。解析诸如OSPF、EIGRP等网络协议, 形成格式化的解析数据</p>
        <p>2.多厂商第三方设备解析</p>
        <p>对复杂网络中的多厂商第三方设备以及特殊设备逐个适配</p>
        <p>3.产生报告导出</p>
        <p>将分析数据生成特定格式分析报告,主要为Excel, 交付给前端网络工程师</p>
        <p><i class="emphasize">应用技术：</i>Python, ipy, pandas,Numpy, xlrd, xlsxwriter</p>
        <p><i class="emphasize">项目产出：</i>为后续系统功能的开发奠定基础, 增加了系统的实用性</p>
        <hr/>
        <p class="emphasize">三、网络全景视图系统V1（EE-V1） </p>
        <p><i class="emphasize">项目职务：</i>后端开发及前端开发</p>
        <p><i class="emphasize">项目简介：</i>此项目为EE系统的Web可视化Dashboard的初级版本（实习期项目）</p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>负责用户上传数据数据流实时状态监测</p>
        <p>网络设备配置文件上传是数据的初始入口, 由于数据分析分为多个阶段, 为保证状态监控实时性, 使用websocket技术来实现,
          前端使用Javascript+bootstrap, 后端使用web2py + tornado websocket
          server, 从而实现及时发现用户上传的新数据类型及失败操作, 最终对用户提供高效支持</p>
        <p><i class="emphasize">应用技术：</i>Python, web2py, nginx, websocket，javascript </p>
        <p><i class="emphasize">项目产出：</i>团队再进行测试的时候, 数据输入不再单纯使用api, 转为图形化操作,
          开发团队在进行测试效率极大提高, 产品经理和网络工程师等非技术团队成员也可以x自行上传数据, 平衡了开发团队的工作量</p>
        <hr/>

        <p class="emphasize">四、企业网络数据收集工具（EE-CT）</p>
        <p><i class="emphasize">项目职务：</i>后端开发</p>
        <p><i class="emphasize">项目简介：</i>随着EE系统的初步应用, 产生了大量的原始网络设备配置数据收集需求,
          该项目期望产出跟随EE版本变化的自动化数据收集器</p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>1.针对不同的应用场景, 制作多版本数据收集工具, 及时对接交付网络工程师</p>
        <p>2.与网络工程师合作, 为网络工程师提供订制化的数据处理和分析</p>
        <p><i class="emphasize">应用技术：</i>Python, paramiko, netmiko</p>
        <p><i class="emphasize">项目产出：</i>使用自动化工具后, 网络工程师们有关数据收集项目的时间管理单位发生改变, 从月减小到天</p>
        <hr/>

        <p class="emphasize">五、网络全景视图系统V2（EE-V2）</p>
        <p><i class="emphasize">项目职务：</i>后端开发、运维</p>
        <p><i class="emphasize">项目简介：</i>EE-V2为EE-V1的重构项目, 加入了更多的分布式设计, EE-V2期望提升系统性能、
          提高系统承载能力、提高用户体验并支持新的功能特性
        </p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>1.从零开始为团队搭建项目新版本架构, 搭建新架构需要的服务,为团队技术选型应用提前试水, 整合复用Cisco其他研发部门的开放api，
          提高新版本团队开发效率</p>
        <p>2.配合Devops工具实现多版本运维半自动化, 更新公共数据相关数据库, 进行系统的备份和管理员邮件监控, 降低开发部署耗时,
          实现对系统资源的初级监控, 提高约50%部署和运维效率</p>
        <p>3.负责定期更新爬取公共数据相关数据库, 进行系统的备份和管理员邮件监控，根据新版本系统最新需求同步兼容旧版本系统, 双版本并行维护,
          充分满足网络工程师项目业务需求</p>
        <p><i class="emphasize">应用技术：</i>golang, Beego, grpc, python, RabbitMQ, redis-server, memcache,
          elasticserach, mongodb, nodejs等</p>
        <p><i class="emphasize">项目产出：</i>系统的完善提供了足够的承载能力和更丰富的功能, 客户范围从国内扩大到APJC</p>
        <hr/>
        <p class="emphasize">六、EE监控微服务（EE-M）</p>
        <p><i class="emphasize">项目职务：</i>后端开发、运维</p>
        <p><i class="emphasize">项目简介：</i>用于维护EE系统开发的微服务</p>
        <p><i class="emphasize">项目职责：</i></p>
        <p>1.负责寻找系统运维需求, 提供运维角度建议, 与团队成员对接, 设计并实现相关需求</p>
        <p>2.挖掘数据价值, 探索系统中台能力, 积极反馈</p>
        <p><i class="emphasize">应用技术：</i>golang, Beego, grpc, python</p>
        <p><i class="emphasize">项目产出：</i>管理系统逐步完善, 系统拥有一定自检能力</p>
      </div>
    </div>
  </div>
  <div id="bottom-space">&nbsp;</div>
</template>

<script>
export default {
  name: "ResumeGolang"
}
</script>

<style scoped>
#top-div {
  position: relative;
  left: 10px;
  top: 10px;
  width: 890px;
  height: 2000px;
  border: 10px solid;
  border-color: #5A87E1;
  border-radius: 30px;
}
.space{
  height: 30px;
}
#bottom-space {
  position: relative;
  top:50px;
  height: 40px;
}

#top-div .emphasize {
  font-family: 黑体;
  font-size: 10pt;
  color: black;
  font-weight: 800;
  margin-top: 5px;
}

#top-div .itemtitle {
  font-family: "华文楷体", STKaiti;
  font-size: 16pt;
  background: #5A87E1;
  color: white;
  margin: 4px;
  border-radius: 5px
}

#top-div p {
  font-family: "华文楷体", STKaiti;
  font-size: 10pt;
  color: black;
  font-weight: 500;
  margin: 4px
}

#top-div span {
  font-family: "华文楷体", STKaiti;
  font-size: 16pt;
  color: #5A87E1;
  font-weight: 800
}

#left-half {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 155px;
  height: 900px;
}

#left-top {
  position: relative;
  left: 0;
  top: 4px;
  width: 150px;
  height: 212px;
  display: inline-block;
  background-image: url("../../assets/me_bg.png");
  background-repeat: repeat;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px
}


#left-middle {
  position: relative;
  left: 0;
  top: 4px;
  width: 155px;
  height: 280px;
  display: inline-block;
}

#left-bottom {
  position: relative;
  left: 0;
  top: 4px;
  width: 150px;
  height: 100px;
  display: inline-block;
}


#right-half {
  position: relative;
  left: 165px;
  top: 10px;
  width: 700px;
  height: 900px;
  display: inline-block;
}

#right-top {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: auto;
  display: inline-block;
}

#right-top p {
}

#right-middle {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: auto;
  display: inline-block;
}

#right-middle p {
  margin-top: 2px;
  margin-bottom: 2px;
}

#right-bottom {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: 200px;
  display: inline-block;
}

#right-bottom p {
  margin-top: 2px;
  margin-bottom: 2px;
}

#left-top img {
  width: 150px;
  height: 213px;
  position: relative;
  bottom: 0;
}
</style>