<template>
  <div>
    <select v-model="selectedValue" @change="handleSelect">
      <option v-for="(options,id) in selectClassData" :key="id" :value="options">
        {{ options.title }}
      </option>
    </select>
  </div>

  <div v-show=resumeDivStatus.golangResumeDiv>
    <ResumeGolang/>
  </div>
  <div v-show=resumeDivStatus.pythonResumeDiv>
    <ResumePython/>
  </div>

</template>

<script>
import ResumeGolang from "./ResumeGolang";
import ResumePython from "./ResumePython"

export default {
  name: "resumeSelect",
  components: {ResumeGolang, ResumePython},
  data: () => ({
    resumeDivStatus: {
      "golangResumeDiv": true,
      "pythonResumeDiv": false,
    },
    selectClassData: [
      {id:1,title: "Golang"},
      {id:2,title: "Python"}
    ],
    selectedValue:{id:1,title: "Golang"}
  }),
  methods: {
    handleSelect() {
      switch (this.selectedValue.id){
        case 1:
          this.resumeDivStatus={
            "golangResumeDiv": true,
            "pythonResumeDiv": false,
          }
          break;
        case 2:
          this.resumeDivStatus={
            "golangResumeDiv": false,
            "pythonResumeDiv": true,
          }
          break;
        default:
          this.resumeDivStatus={
            "golangResumeDiv": true,
            "pythonResumeDiv": false,
          }
      }
    },
  }
}
</script>

<style scoped>

</style>