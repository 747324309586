<template>
  <input type="text">
  <input type="password">
</template>

<script>
export default {
  name: "LoginPage"
}
</script>

<style scoped>
.input{
  width: 10%;
}
</style>