<template>
  <div>
    <input type="color" v-model="pickedColor" ref="colorPickerInput" >
  </div>
</template>

<script>
export default {
  name: "colorPicker",
  expose: ["getPickedColor"],
  data: () => ({
    pickedColor: "white"
  }),
  methods: {
    getPickedColor() {
      return this.pickedColor
    }
  }
}
</script>

<style scoped>

</style>