<template>
    <div id="top-div">
      <div id="left-half">
        <div id="left-top">
          <img src="../../assets/me.png">
        </div>
        <div id="left-middle">
          <h3 class="itemtitle">&nbsp;求职意向</h3>
          <p>后端开发/运维</p>
          <h3 class="itemtitle">&nbsp;个人简介</h3>
          <p id="name">姓名：孙思原</p>
          <p id="nickname">昵称：抖来眯</p>
          <p><a href="https://urousi.com/resume-python">https://urousi.com</a></p>
          <h3 class="itemtitle">&nbsp;教育经历</h3>
          <a herf></a>
          <p>2013.09-2017.07</p>
          <p>学历：本科</p>
          <p>物联网工程专业 </p>
          <p>PMP, CCNP</p>
          <h3 class="itemtitle">&nbsp;获得证书</h3>
          <p>CET6</p>
          <p>RHCE红帽认证</p>
          <p>中级网络工程师</p>
        </div>
        <div id="left-bottom">
          <h3 class="itemtitle">&nbsp;联系方式</h3>
          <p>Email: </p>
          <p>siysun@outlook.com</p>
          <p>Tel/Wechat:</p>
          <p> 15502635773</p>
        </div>
      </div>
      <div id="right-half">
        <div id="right-top">
          <h3 class="itemtitle">&nbsp;专业技术&nbsp;</h3>
          <p>1.后端：</p>
          <p>深入理解Python,</p>
          <p>熟悉分布式计算架构相关服务(mongodb， elasticsearch，redis， rabbitmq)，了解Java， Golang ，了解微服务架构</p>
          <p>2.运维技能：</p>
          <p>熟悉linux运维，了解Ansible， Docker， K8S等工具 ，可定制开发运维工具</p>
          <p>3.网络技能：</p>
          <p>了解主流网络协议，了解基础网络运维</p>
        </div>

        <div id="right-middle">
          <h3 class="itemtitle">&nbsp;工作经历</h3>
          <p class="exp_year">2016.10-2017.07&nbsp;&nbsp;Cisco Systems Dalian 研发实习生 </p>
          <p> 1.网络协议数据解析</p>
          <p>网络设备各厂商设备多种网络协议数据解析，提供网络数据分析系统的数据基础 </p>
          <p>2.网络解析系统数据输入模块</p>
          <p>开发原始数据输入功能及数据解析状态实时更新</p>
          <p class="exp_year">2017.08-2020.11&nbsp;&nbsp;Cisco Systems Dalian 研发/运维</p>
          <p>1.开发：系统后端重构，旧版本更新支持</p>
          <p>2.运维:从 零开始搭建团队开发环境，新版本的测试和部署</p>
          <p>3.客户支持：关注用户反馈，支持用户临时需求，提高项目的敏捷性</p>
        </div>
        <div id="right-bottom">
          <h3 class="itemtitle">&nbsp;项目经历</h3>
          <p class="done_projects">一、企业级网络设备分析系统V0.1-V1.x开发</p>
          <p>该项目为企业级网络分析系统。该项目通过收集企业网拓扑中网络设备的配置文件，解析网络设备配置，形成可视化逻辑物理拓扑图，提供企业级网络设备的全景预览和分析建议</p>
          <p>1.负责用户上传数据数据流实时状态监测，为保证状态监控实时性，使用websocket技术来实现，前端使用Javascript+bootstrap，后端使用web2py + tornado
            实现及时发现用户上传的新数据类型及失败操作，最终对用户提供高效支持。</p>
          <p>2. 参与网络设备配置数据解析微服务开发，提供可视化数据产出数据基础。解析诸如OSPF、EIGRP等网络协议，形成格式化的解析数据</p>
          <p class="done_projects">二、企业级网络设备分析系统V2.x-V4.x开发维护</p>
          <p>网络设备分析系统V2.x版本 开始从单机向分布式过度，经过两次重构到达V4.x版本，主要针对之前的版本做了系统性能、用户体验等功能性改进</p>
          <!--          <p>1.从零开始为团队搭建项目新版本用的测试VM，搭建新架构需要的服务，包括后端:RabbitMQ，redis-server，memcache， elasticserach，-->
          <!--                mongodb，前端:nodejs等。为团队技术选型应用提前试水，提高新版本团队开发效率</p>-->
          <p>1.结合shell、python脚本，配合Devops工具实现多版本运维半自动化，更新公共数据相关数据库，进行系统的备份和管理员邮件监控，降低开发部署耗时，
            实现对系统资源的初级监控，提高约50%部署和运维效率。</p>
          <p>2. 根据新版本系统最新需求同步兼容旧版本系统，充分满足网络工程师项目业务需求</p>
          <p class="done_projects">三、企业网络数据收集和数据分析项目</p>
          <p>1.针对不同的应用场景，制作多版本数据收集工具，赋能网络工程师，实现数据收集效率数倍提升</p>
          <p>2.使用python脚本和打包软件提供订制化的数据分析，从而为设计规划分析企业级网络的网络工程师赋能，实现整体网络分析效率数倍提升</p>
          <h3 class="itemtitle">&nbsp;自我评价</h3>
          <p>热爱技术，吃苦耐劳，积极乐观，积极乐观，擅长规划和记录，擅长自驱动</p>
          <p>擅长以目标驱动快速扩展技术栈并学以致用</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "ResumePython"
}
</script>

<style scoped>
#top-div {
  position: relative;
  left: 10px;
  top: 10px;
  width: 890px;
  height: 2000px;
  border: 10px solid;
  border-color: #5A87E1;
  border-radius: 30px;
}
.space{
  height: 30px;
}
#bottom-space {
  position: absolute;
  top:2150px;
  height: 40px;
}

#top-div .emphasize {
  font-family: 黑体;
  font-size: 10pt;
  color: black;
  font-weight: 800;
  margin-top: 5px;
}

#top-div .itemtitle {
  font-family: "华文楷体", STKaiti;
  font-size: 16pt;
  background: #5A87E1;
  color: white;
  margin: 4px;
  border-radius: 5px
}

#top-div p {
  font-family: "华文楷体", STKaiti;
  font-size: 10pt;
  color: black;
  font-weight: 500;
  margin: 4px
}

#top-div span {
  font-family: "华文楷体", STKaiti;
  font-size: 16pt;
  color: #5A87E1;
  font-weight: 800
}

#left-half {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 155px;
  height: 900px;
}

#left-top {
  position: relative;
  left: 0;
  top: 4px;
  width: 150px;
  height: 212px;
  display: inline-block;
  background-image: url("../../assets/me_bg.png");
  background-repeat: repeat;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px
}


#left-middle {
  position: relative;
  left: 0;
  top: 4px;
  width: 155px;
  height: 280px;
  display: inline-block;
}

#left-bottom {
  position: relative;
  left: 0;
  top: 4px;
  width: 150px;
  height: 100px;
  display: inline-block;
}


#right-half {
  position: relative;
  left: 165px;
  top: 10px;
  width: 700px;
  height: 900px;
  display: inline-block;
}

#right-top {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: auto;
  display: inline-block;
}

#right-top p {
}

#right-middle {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: auto;
  display: inline-block;
}

#right-middle p {
  margin-top: 2px;
  margin-bottom: 2px;
}

#right-bottom {
  position: relative;
  left: 10px;
  top: 0;
  width: 700px;
  height: 200px;
  display: inline-block;
}

#right-bottom p {
  margin-top: 2px;
  margin-bottom: 2px;
}

#left-top img {
  width: 150px;
  height: 213px;
  position: relative;
  bottom: 0;
}
</style>