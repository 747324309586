import {createRouter, createWebHistory} from "vue-router";
import HomePage from '../components/BasicPage/HomePage';
import NotFoundPage from "@/components/BasicPage/NotFoundPage";
import WordRevise from '../components/WordRevise/WordRevise';
import ResumeSelect from "@/components/Resume/ResumeSelect";
import ResumeGolang from "@/components/Resume/ResumeGolang";
import ResumePython from "@/components/Resume/ResumePython";
import LoginPage from "@/components/Login/LoginPage";
import RoutesTable from "@/components/DevHelper/RoutesTable";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/routes',
            component: RoutesTable,
        },{
            path: '/test',
            component: LoginPage,
        },
        {
            path: '/',
            component: HomePage,
        },{
            path: '/word-revise',
            component: WordRevise,
        },{
            path: '/resume-select',
            component: ResumeSelect,
        },{
            path: '/resume-golang',
            component: ResumeGolang,
        },{
            path: '/resume-python',
            component: ResumePython,
        }
        ,{
            path: '/:catchAll(.*)',
            component: NotFoundPage,
        }
    ]
})

export default router;