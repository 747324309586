<template>
  <div>
    welcome
  </div>
</template>

<script>
export default {
  name: "HomePage",
}
</script>

<style scoped>

</style>